import React, { Fragment } from "react"

import Footer from "../components/footer/footer"
import Seo from "../components/seo/seo"
import Dyslexia from "../components/apps/dyslexia/dyslexia"

export default class DyslexiaPage extends React.Component {
  render() {
    return(
      <Fragment >
        <Dyslexia />
        <Footer />
      </Fragment>
    )
  }
}

export const Head = () => {
  return <Seo title={"dyslexia.ai"} />;
};