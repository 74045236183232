import React from "react";

import DyslexiaAiHero from "./hero";

export default function Dyslexia() {
  return (
    <section>
      <DyslexiaAiHero />
      {/* <p className="">
        We've designed and developed a beautiful modern app, to aid and improve
        dyslexic students' reading and writing. Currenly, Dyslexia.ai is only
        out for Android, and coming to iOS in the next few months.
      </p>

      <Fade bottom>
        <section className="">
          Features
          <ul className="">
            <li className="">3 Games</li>
            <li className="">Over 10,000 Words</li>
            <li className="">Augmented Reality (AR) Rewards</li>
            <li className="">OCR with Text-to-Speech</li>
            <li className="">Voice Recognition</li>
            <li className="">Personalised Account</li>
            <li className="">Instant App</li>
            <li className="">Dark Mode</li>
            <li className="">Material Design</li>
          </ul>
        </section>
      </Fade>

      <p className="">
        <a
          href="/privacy/privacy-policy-dyslexia-go.html"
          rel="noopener noreferrer"
          target="_blank">
          Privacy Policy
        </a>
      </p> */}
    </section>
  );
}
